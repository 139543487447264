.msg-block {
    .msg-content p {
        margin: 0.6rem 0 0.4rem 0;
    }
    .msg-content ol {
        padding-inline-start: 25px;
    }
    .msg-content ul {
        padding-inline-start: 25px;
    }
    .msg-content img {
        width: 100%;
        max-width: 40rem;
    }
}

// markdown table
html[data-theme='light'] .msg-block {
    table {
        background-color: #f8f8f8;
        margin: 0 auto;
        border-collapse: collapse;
        font-size: 1em;
        font-family: Arial, sans-serif;
        line-height: 1.2;
        border: 1px solid #ddd;
    }
    table th,
    table td {
        padding: 0.5em 1.2em;
        border: 1px solid #ddd;
        text-align: center;
    }
    table th {
        background-color: #e5e5e5;
        font-weight: bold;
        color: #333;
    }
    table tr:nth-child(even) {
        background-color: #f2f2f2;
    }
}
html[data-theme='dark'] .msg-block {
    table {
        background-color: #333;
        color: #fff;
        margin: 0 auto;
        border-collapse: collapse;
        font-size: 1em;
        font-family: Arial, sans-serif;
        line-height: 1.2;
        border: 1px solid #666;
    }
    table th,
    table td {
        padding: 0.5em 1.2em;
        border: 1px solid #666;
        text-align: center;
    }
    table th {
        background-color: #555;
        font-weight: bold;
    }
    table tr:nth-child(even) {
        background-color: #444;
    }
}

// code block
.code-block-wrapper {
    width: 100%;
}
.code-header {
    margin: 0;
    background-color: #333;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}
.code-block {
    box-sizing: border-box;
    padding: 10px;
    max-width: 100%;
    overflow: auto;
    margin: 0;
}
.rendering .code-block-wrapper div.copy-action {
    display: none;
}
.code-block-wrapper .code-lang {
    margin-left: 6px;
    padding: 2px;
}
.code-block-wrapper div.copy-action {
    margin-right: 6px;
    cursor: pointer;
    display: inline-block;
}
.code-block-wrapper div.copy-action {
    background-color: rgba(144, 202, 249, 0.16);
    padding: 2px;
}
.code-block-wrapper div.copy-action:hover {
    background-color: rgba(144, 202, 249, 0.36);
}
.code-block-wrapper:hover div.copy-action {
    opacity: 1;
}

// message
html[data-theme='light'] {
    .assistant-msg {
        background-color: #fafafa;
    }
    // .system-msg {
    //     background-color: #fafafa;
    // }
}
html[data-theme='dark'] {
    .assistant-msg {
        background-color: #212121;
    }
    // .system-msg {
    //     background-color: #212121;
    // }
}
