@use 'sass:meta';

html > body {
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
        Arial, sans-serif; */
    /* padding: 0; */
    margin: auto;
}

html[data-theme='dark'] {
    // ------------ highlight.js ---------------

    // https://stackoverflow.com/questions/59257368/how-to-dynamically-change-the-theme-using-highlight-js
    @include meta.load-css('highlight.js/styles/github-dark');

    // ------------ 滚动条 ---------------

    /* 设置滚动条的宽度, 背景色与边框 */
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #333333;
        border-radius: 6px;
    }

    ::scrollbar {
        width: 6px;
        height: 6px;
        background-color: #333333;
        border-radius: 6px;
    }

    /* 滚动条上的滑块 */
    ::-webkit-scrollbar-thumb {
        background-color: #b1b1b1;
        border-radius: 6px;
    }

    /* 鼠标悬停在滑块上时 */
    ::-webkit-scrollbar-thumb:hover {
        background-color: #8c8c8c;
    }

    /* 滚动条的轨道 */
    ::-webkit-scrollbar-track {
        background-color: #333333;
        border-radius: 6px;
    }

    /* 鼠标悬停在滚动条轨道上时 */
    ::-webkit-scrollbar-track:hover {
        background-color: #2c2c2c;
    }

    /* 滚动条上下按钮 */
    ::-webkit-scrollbar-button {
        display: none;
    }

    a {
        color: #fff;
    }
}

html[data-theme='light'] {
    // ------------ highlight.js ---------------

    // https://stackoverflow.com/questions/59257368/how-to-dynamically-change-the-theme-using-highlight-js
    // @include meta.load-css('highlight.js/styles/github');
    @include meta.load-css('highlight.js/styles/github-dark');

    // ------------ 滚动条 ---------------

    /* 设置滚动条的宽度, 背景色与边框 */
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f5f5f5;
        border-radius: 6px;
    }

    ::scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f5f5f5;
        border-radius: 6px;
    }

    /* 滚动条上的滑块 */
    ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 6px;
    }

    /* 鼠标悬停在滑块上时 */
    ::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a0;
    }

    /* 滚动条的轨道 */
    ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 6px;
    }

    /* 鼠标悬停在滚动条轨道上时 */
    ::-webkit-scrollbar-track:hover {
        background-color: #dcdcdc;
    }

    /* 滚动条上下按钮 */
    ::-webkit-scrollbar-button {
        display: none;
    }

    a {
        color: #333;
    }
}
